import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Register from "./Register";

const NotFoud = () => {
  return (
    <div style={{ textAlign: 'center', padding: "10%" }}>
      <h1>ERROR 404</h1>
      <h3>Page Not Found</h3>
    </div>
  )
};

const Test = () => {
  return (
    <h1>Test Page</h1>
  )
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Register />
        </Route>
        <Route exact path="/test">
          <Test />
        </Route>
        <Route path="*">
          <NotFoud />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
