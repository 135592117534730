import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Input, Button, Row, Col, Select, Spin, message, Typography } from 'antd';
import GlobalPhoneCodes from './constants/globalCallingCodes.json';
import axios from 'axios';
import 'antd/dist/antd.css';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const { Option } = Select;
const { Text } = Typography;
const phoneCodes = GlobalPhoneCodes.countries;

const Register = (props) => {

  const [loader, setLoader] = useState(true);
  const [WebinarsData, setWebinarsData] = useState();
  const [availableTimeZones, setAvailableTimeZones] = useState([]);
  const [availableSchedules, setAvailableSchedules] = useState([]);

  const [form] = Form.useForm();

  const currentLocation = useLocation();
  // const utm_ref_link = new URLSearchParams(currentLocation.search).get("utm_ref_link");
  const utm_src = new URLSearchParams(currentLocation.search).get("utm_source");
  const utm_med = new URLSearchParams(currentLocation.search).get("utm_medium");
  const utm_camp = new URLSearchParams(currentLocation.search).get("utm_campaign");
  const utm_content = new URLSearchParams(currentLocation.search).get("utm_content");
  const utm_term = new URLSearchParams(currentLocation.search).get("utm_term");
  const fbclid = new URLSearchParams(currentLocation.search).get("fbclid");
  const gclid = new URLSearchParams(currentLocation.search).get("gclid");
  const clid = (fbclid ?? gclid);
  const utm_ref_link = clid ? clid : null;

  console.log(utm_ref_link);
  console.log(utm_src);
  console.log(utm_med);
  console.log(utm_camp);
  console.log(utm_content);
  console.log(utm_term);

  useEffect(() => {

    const getCountryData = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        form.setFieldsValue({
          phoneCountryCode: response.data.country_calling_code,
          city: response.data.city
        });
      }
      catch (error) {
        console.log(error);
      }
    };

    const getWebinarData = async () => {
      try {
        const webinarDataResp = await axios.get(`${window.location.origin}/webinars-1613558403109.json`);
        console.log(webinarDataResp.data);
        const timeZones = [];
        await webinarDataResp.data.webinars.forEach((data) => {
          const ti = timeZones.findIndex((di) => data.timezone === di);
          if (ti < 0) timeZones.push(data.timezone);
        });
        setWebinarsData(webinarDataResp.data);
        setAvailableTimeZones(timeZones);
      } catch (error) {
        console.log(error);
      }
    }

    getWebinarData().then(() => {
      getCountryData().then(() => {
        setLoader(false);
      });
    }).catch((error) => {
      message.error('Some thing went wrong while initating data, please reload the page');
      setLoader(false);
      console.log(error);
    });

    // eslint-disable-next-line
  }, []);

  const getAvailableSchedules = (tz) => {
    setLoader(true);
    const schedules = [];
    form.resetFields(['dateSelected']);
    WebinarsData.webinars.forEach((data) => {
      if (data.timezone === tz) {
        data.schedules.forEach((dataSchedule) => {
          const tm = new Date() < new Date(dataSchedule);
          if (tm) schedules.push(dataSchedule);
        })
      }
    });
    console.log(schedules);
    setAvailableSchedules([...schedules]);
    setLoader(false);
  };

  const leftDays = (requredDate) => {
    const currentDate = new Date();
    const actualDate = new Date(requredDate);
    const differnceDate = actualDate.getTime() - currentDate.getTime();
    return parseInt(differnceDate / (1000 * 60 * 60 * 24));
  };

  const onFinish = (values) => {
    setLoader(prevState => !prevState);
    splitNames(values.name).then((mName) => {
      const data = {
        firstName: mName.fn,
        lastName: mName.ln,
        phone: values.phoneNumber,
        phoneCountryCode: values.phoneCountryCode,
        email: values.email,
        timezone: values.timeZone,
        date: values.dateSelected,
        city: values.city,
        desc: values.message,
        webinarID: WebinarsData.webinarID,
        webinarHash: WebinarsData.webinarHash,
        scheduleID: WebinarsData.scheduleID,
        utmRefLink: utm_ref_link,
        utmSrc: utm_src,
        utmMed: utm_med,
        utmCamp: utm_camp,
        utmContent: utm_content,
        utmTerm: utm_term,
      };
      console.log('Success:', data);
      sendData(data);
      registerUser(data);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const splitNames = (usrName) => {
    return new Promise((resolve, reject) => {
      let fn, ln;
      const i = usrName.lastIndexOf(' ');
      if (i > -1) {
        fn = usrName.substring(0, i);
        ln = usrName.substring(i + 1);
        resolve({ fn, ln });
      }
      else {
        fn = usrName;
        ln = ''
        resolve({ fn, ln });
      }
    });
  };

  const sendData = async (formData) => {
    try {
      const rsp = await axios({
        url: "https://2tz6e74yv6.execute-api.us-east-2.amazonaws.com/contact",
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'dataType': 'json',
          'crossDomain': 'true',
        }
      });
      if (rsp.status === 200) {
        setLoader(prevState => !prevState);
        message.success('Thanks for your message, we will get back to you soon');
        form.resetFields(['name', 'email', 'dateSelected', 'city', 'timeZone', 'message', 'phoneNumber']);
      }
      else {
        message.error('Failed to send your message, please try again later');
      }
    }
    catch (e) {
      setLoader(prevState => !prevState);
      message.error('Failed to send your message, please try again later');
      console.log(e);
    }
  };

  const registerUser = async (data) => {
    const url = `https://event.webinarjam.com/register/1click/${data.webinarID}/${data.webinarHash}?first_name=${data.firstName}&last_name=${data.lastName}&email=${data.email}&phone_country_code=${data.phoneCountryCode}&phone_number=${data.phone}&schedule_id=${data.scheduleID}`;
    console.log(url);
    window.open(url, "_blank");
  };

  return (
    <div style={{ paddingTop: "20vh" }}>
      <Spin style={{ height: '40vh' }} spinning={loader} tip="Loading">
        {
          !loader ?
            <Row>
              <Col span={10} offset={6}>
                <Form
                  {...layout}
                  form={form}
                  name="contact"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Time Zone"
                    name="timeZone"
                    rules={[
                      {
                        required: true,
                        message: 'Please input time zone',
                      },
                    ]}
                  >
                    <Select onSelect={(value) => getAvailableSchedules(value)}>
                      {availableTimeZones.map((tz, i) => (
                        <Option key={`${i}.${tz}`} value={tz}>{tz}</Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="dateSelected"
                    label="Date"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Date"
                    >
                      {availableSchedules.map((sh, i) => (
                        <Option key={`${i}.${sh}`} value={sh}>{sh}<br />
                          <Text type="secondary">Starts in {leftDays(sh)} days </Text>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your name',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item label="Phone" required={true}>
                    <Input.Group compact>
                      <Form.Item
                        name="phoneCountryCode"
                        noStyle
                        initialValue={"+1"}
                        rules={[{ required: true, message: 'Country code is requried' }]}
                      >
                        <Select style={{ width: '30%' }}>
                          {phoneCodes.map((c, i) => (
                            <Option key={`${i}.${c.name}`} value={c.code}>{c.code} ( {c.name} ) </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="phoneNumber"
                        noStyle
                        rules={[{ required: true, pattern: new RegExp(/\d+/g), message: 'Phone number is required' }]}
                      >
                        <Input style={{ width: '70%' }} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: 'Please input city',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="More Information"
                    name="message"
                  >
                    <Input.TextArea />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            :
            ''
        }
      </Spin>
    </div>
  );
};

export default Register;